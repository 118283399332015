import React, { useState, useEffect, useContext } from 'react';
import { observer } from 'mobx-react-lite';
import {
    Select,
    Table,
    message,
    Modal,
    Popconfirm,
    Input,
    InputNumber,
    Checkbox,
} from 'antd';
import { StoreContext } from '@/stores';
import { defaultAxios } from '@/utils';
import { ITuoKeTongChildData, ITuoKeTongModifyChildPayload } from '../../store';
import './index.scss';
import ModifyChildModal, { IModifyChildValues } from '../ModifyChildModal';

export interface IExpandedTuoKeTongRowProps {
    license_id: number;
}

const ExpandedTuoKeTongRow: React.FC<IExpandedTuoKeTongRowProps> = props => {
    const store = useContext(StoreContext);
    const memberTuoKeTongStore = store.getDefaultMemberTuoKeTongStore();
    const {
        modifyTuoKeTongChild,
        deleteTuoKeTongChild,
        bindTuoKeTongChild,
        unBindTuoKeTongChild,
    } = memberTuoKeTongStore;
    const { license_id } = props;
    const [fetchChildListLoading, setFetchChildListLoading] = useState(false);
    const [childListData, setChildListData] = useState<ITuoKeTongChildData[]>(
        [],
    );
    const [modifyChildModalVis, setModifyChildModalVis] = useState(false);
    const [selectedChildRow, setSelectedChildRow] = useState<
        ITuoKeTongChildData
    >();
    const [childPhone, setChildPhone] = useState('');
    const [fillPhoneModalVis, setFillPhoneModalVis] = useState(false);
    const fetchChildList = async () => {
        setFetchChildListLoading(true);
        const url = '/crm/member/tuoketong/getTeamInfo';
        const [d, e] = await defaultAxios.get(url, {
            license_id: license_id,
        });
        setFetchChildListLoading(false);
        const data = d?.data;
        if (data && Array.isArray(data)) {
            setChildListData(data);
        } else {
            message.error(d?.msg || '获取子列表失败');
        }
    };
    useEffect(() => {
        if (license_id) {
            fetchChildList();
        }
    }, [license_id]);
    const handleModifyChild = async (values: IModifyChildValues) => {
        const res = await modifyTuoKeTongChild({
            ...values,
        });
        if (res) {
            message.success('修改子账号成功');
            setModifyChildModalVis(false);
            fetchChildList();
        } else {
            message.error('修改子账号失败');
        }
    };
    const bindTuoKeTong = async (childId: number, childPhone: string) => {
        const res = await bindTuoKeTongChild(childId, childPhone);
        if (res) {
            message.success('绑定子账号成功');
            fetchChildList();
        } else {
            message.error('绑定子账号失败');
        }
    };
    const fillPhoneModal = (childId: any) => {
        return (
            <Modal
                title="绑定子账号需要手机号,请填写手机号"
                visible={fillPhoneModalVis}
                onOk={() => {
                    setFillPhoneModalVis(false);
                    bindTuoKeTong(childId, childPhone);
                }}
                onCancel={() => {
                    setFillPhoneModalVis(false);
                }}
            >
                <div className="fill-phone-content">
                    <label htmlFor="phone">手机号：</label>
                    <Input
                        className="fill-phone-input"
                        name="phone"
                        onChange={e => {
                            setChildPhone(e.target.value);
                        }}
                    />
                </div>
            </Modal>
        );
    };
    const renderTuoKeTongChildOperator = (item: ITuoKeTongChildData) => {
        return (
            <p className="tuoketong-child-op">
                <span
                    onClick={() => {
                        setModifyChildModalVis(true);
                        setSelectedChildRow(item);
                    }}
                >
                    修改子账号
                </span>
                <Popconfirm
                    title={'确定要绑定该子账号?'}
                    onConfirm={async () => {
                        if (item.sub_license_st) {
                            message.error('该账户已经被绑定');
                        } else {
                            if (!item.phone) {
                                setFillPhoneModalVis(true);
                            } else {
                                bindTuoKeTong(item.id, item.phone);
                            }
                        }
                    }}
                >
                    <span onClick={() => setSelectedChildRow(item)}>
                        绑定子账号
                    </span>
                </Popconfirm>
                <Popconfirm
                    title={'确定要解绑该子账号?'}
                    onConfirm={async () => {
                        if (!item.sub_license_st) {
                            message.error('该账户已经解绑');
                        } else {
                            const res = await unBindTuoKeTongChild(item.id);
                            if (res) {
                                message.success('解绑子账号成功');
                                fetchChildList();
                            } else {
                                message.error('解绑子账号失败');
                            }
                        }
                    }}
                >
                    <span>解绑子账号</span>
                </Popconfirm>
                <Popconfirm
                    title={'确定要删除子账号?'}
                    onConfirm={async () => {
                        const res = await deleteTuoKeTongChild(item.id);
                        if (res) {
                            message.success('删除子账号成功');
                            fetchChildList();
                        } else {
                            message.error('删除子账号失败');
                        }
                    }}
                >
                    <span>删除子账号</span>
                </Popconfirm>
            </p>
        );
    };
    const columns = [
        {
            title: '席位ID',
            key: 'id',
            dataIndex: 'id',
        },
        {
            title: '姓名',
            key: 'name',
            dataIndex: 'name',
        },
        {
            title: '手机号',
            key: 'phone',
            dataIndex: 'phone',
        },
        {
            title: '是否已绑定',
            key: 'sub_license_st',
            dataIndex: 'sub_license_st',
            render: (item: number) => {
                return item ? '是' : '否';
            },
        },
        {
            title: '是否管理员',
            key: 'is_admin',
            dataIndex: 'is_admin',
            render: (item: number) => {
                return item ? '是' : '否';
            },
        },
        {
            title: '加好友剩余',
            key: 'addfr_left',
            dataIndex: 'addfr_left',
        },
        {
            title: '极速联系剩余',
            key: 'uh_left',
            dataIndex: 'uh_left',
        },
        {
            title: '电话沟通（旧）',
            key: 'telephone_left',
            dataIndex: 'telephone_left',
        },
        {
            title: '电话直联（新）',
            key: 'direct_mobile_left',
            dataIndex: 'direct_mobile_left',
        },
        {
            title: '是否基础款',
            key: 'is_sale',
            dataIndex: 'is_sale',
            render: (item: number) => {
                return item ? '是' : '否';
            },
        },
        {
            title: '批量功能子账号',
            key: 'is_batch',
            dataIndex: 'is_batch',
        },
        {
            title: '是否营销',
            key: 'is_market',
            dataIndex: 'is_market',
            render: (item: number) => {
                return item ? '是' : '否';
            },
        },
        {
            title: '过期时间',
            key: 'expire_time',
            dataIndex: 'expire_time',
        },
        {
            title: '操作',
            width: '300px',
            key: 'operator',
            render: (item: any) => {
                return renderTuoKeTongChildOperator(item);
            },
        },
    ];
    const renderTuoKeTongChildTable = () => {
        return (
            <Table<ITuoKeTongChildData>
                loading={fetchChildListLoading}
                dataSource={childListData}
                pagination={false}
                size={'middle'}
                columns={columns}
            />
        );
    };
    return (
        <>
            <ModifyChildModal
                visible={modifyChildModalVis}
                onCancel={() => setModifyChildModalVis(false)}
                onOk={handleModifyChild}
                selectedChild={selectedChildRow}
                initialValues={
                    selectedChildRow
                        ? {
                              subLicenseId: selectedChildRow.id,
                              isAdmin: selectedChildRow.is_admin,
                              isSale: selectedChildRow.is_sale,
                              isBatch: selectedChildRow.is_batch,
                              isMarket: selectedChildRow.is_market,
                              friend: selectedChildRow.friend,
                              contact: selectedChildRow.contact,
                              telephone: selectedChildRow.telephone,
                              direct_mobile: selectedChildRow.direct_mobile,
                          }
                        : undefined
                }
            />
            {fillPhoneModal(selectedChildRow?.id)}
            <div className="tuoketong-child-table">
                {renderTuoKeTongChildTable()}
            </div>
        </>
    );
};
export default observer(ExpandedTuoKeTongRow);
