import { makeAutoObservable } from 'mobx';
import {
    createDefaultInstanceGetter,
    TPaginationStoreInstance,
    PaginationStore,
} from '@/stores/utils';
import { defaultAxios } from '@/utils';
import { IMemberListStagingSearchData } from '.';
import { message } from 'antd';

export interface IMemberListStagingData {
    id: string | number; // 企业id
    co_full_name: string | number; // 企业名称
    bd_name: string | number; // 销售
    co_identity: string | number; // 账号类型
    adm_name: string | number; // 管理员姓名
    adm_mobile: string | number; // 管理员手机
    mbr_end_time: string | number; // 有效期至
    license_nbr: string | number; // license额度
    team_name: string; // 团队名称
    total_recruit_lic: number | string; // 招聘席位数-总数
    remain_recruit_lic: number | string; // 招聘席位数-剩余
    total_dep_lic: number | string; // 部门席位数-总数
    remain_dep_lic: number | string; // 部门席位数-剩余
    total_sub_lic: number | string; // 子管理员数-总数
    remain_sub_lic: number | string; // 子管理员数-剩余
    mm_coin_balance_total: string | number; // 总点数
    mm_coin_balance_left: string | number; // 剩余点数
    pro_num: string | number; // 项目可分配数
    recruit_lic_pro_num: string | number; // 招聘席位项目数

    batch_invite_balance_total: string | number; // 智能邀约-总数
    batch_invite_balance_left: string | number; // 智能邀约-剩余
    addfr_exchange: string | number; // 加好友-已兑换
    addfr_left: string | number; // 加好友-剩余
    addfr_used: string | number; // 加好友-已使用
    uh_exchange: string | number; // 极速联系-已兑换
    uh_left: string | number; // 极速联系-剩余
    uh_used: string | number; // 极速联系-已使用
    exposure_exchange: string | number; // 极速曝光-已兑换
    exposure_left: string | number; // 极速曝光-剩余
    exposure_used: string | number; // 极速曝光-已使用
    talent_lib_version: string | number; // 人才库版本
    reach_nbr_new_left: string | number; // 虚拟电话-管理员
    reach_nbr: string | number; // 每日虚拟电话权益上限
    attention_company_nbr: string | number; // 关注公司数量
    direct_oppo_monthly: string | number; // 立即沟通（月配额）
    direct_oppo_left: string | number; // 立即沟通（管理员）
    add_fr_monthly: string | number; // 加好友（月配额）
    talent_lib_switch: number; // 人才库开关（1:打开 0:关闭）
    [key: string]: any;
}
export interface IMemberChildData {
    ucard: {
        name: string; // 员工姓名
        mobile: string; // 手机号
        email: string; // 邮箱
        id: string; // uid
        join_time: string; // 开通时间
        expire_time: string; // 结束时间
    };
    report: {
        reach_nbr: number; // 每日虚拟电话权益上限
        id: number | string; // id
        license_serial: number;
        sub_uid: number;
        b_mbr_co_id: number;
        expire_time: string; // 过期时间
        // mock
        position_state: string; // 席位类型
        status: string; // 状态
        // ----
    };
}
export interface IMemberListStagingChildData {
    id: number; // 席位id
    sub_uid: number; // uid
    sub_u_name: number; // 姓名
    license_state: number; // 0禁用 1启用
    mobile: string; // 手机号
    dist_state: number; // 0未分配 1已分配
    lic_type_name: string; // 席位类型
    last_bind: string; // 上次绑定
    role_id: number[]; // 1: 管理员席位 2:子管理员席位  3: 招聘人员席位 4: 部门席位
    bind_log: any[];
    reach_nbr: number;
    add_fr_v2tov3: number;
    is_sub_admin?: number;
    is_ai?: number;
    report_version?: number;
    gold_mobile?: string;
    is_gold_account?: number;
}
export class MemberListStagingStore {
    constructor() {
        makeAutoObservable(this);
    }
    public memberListStagingData: IMemberListStagingData[] = [];
    public memberListStagingLoading: boolean = false;
    public defaultMemberListStagingPagination: TPaginationStoreInstance = new PaginationStore();
    public clearStore = () => {
        this.memberListStagingData = [];
        this.memberListStagingLoading = false;
        this.defaultMemberListStagingPagination = new PaginationStore();
        this.defaultMemberListStagingPagination.pagesize = 20;
    };
    public getMemberListStagingData = async (
        searchData: IMemberListStagingSearchData,
    ) => {
        this.memberListStagingLoading = true;
        const url = '/bff/api/rest/member-list-staging/list';
        const [d, e] = await defaultAxios.post(
            url,
            {
                ...searchData,
                ...this.defaultMemberListStagingPagination.paginationArgs,
            },
            {
                timeout: 400000,
            },
        );
        this.memberListStagingLoading = false;
        if (d === null || d.data === null) {
            message.error('查询失败～');
            return [null, e];
        }
        const data = d.data;
        if (data && Array.isArray(data.data)) {
            if (data.totalSize) {
                this.defaultMemberListStagingPagination.amount = data.totalSize;
            }
            this.memberListStagingData = data.data;
        } else {
            this.memberListStagingData = [];
            message.error(data.msg || '查询失败～');
        }
        const error = e as Error | null;
        return [data, error];
    };
    public exportMemberListStagingData = async (
        searchData: IMemberListStagingSearchData,
    ) => {
        // if (this.channelGatherAnaData.length > 10000) {
        //     Modal.error({
        //         title: '导出提示',
        //         content: (
        //             <p>
        //                 最多支持导出10000条（当前条件共
        //                 {this.channelGatherAnaData.length}
        //                 条，请更换筛选条件再导出
        //             </p>
        //         ),
        //     });
        //     return;
        // }
        const url = `${window.location.origin}/bff/api/rest/member-list-staging/export?start=${searchData.start}&end=${searchData.end}&cid=${searchData.cid}&mobile=${searchData.mobile}`;
        window.open(url, '_blank');
        return;
    };
    // 开通空账号
    public createEmptyAccount = async (
        selectedItem: IMemberListStagingData,
        talentSeat: number,
        departSeat: number,
    ) => {
        const url = '/bff/api/rest/member-list-staging/create-blank';
        const [d, e] = await defaultAxios.post(url, {
            co_id: selectedItem.id,
            recruit_lic: talentSeat,
            dep_lic: departSeat,
        });
        if (d === null || d.data === null) {
            return false;
        }
        const data = d.data;
        if (data === true) {
            return true;
        } else {
            return data.msg || false;
        }
    };
    // 延期申请
    public dueDataSubmit = async (
        selectedItem: IMemberListStagingData,
        duePayload: {
            dueType?: string | number;
            dueReason?: string;
            dueDate?: any;
        },
    ) => {
        const url = '/bff/api/rest/member-list-staging/delay';
        const [d, e] = await defaultAxios.post(url, {
            target_time: duePayload.dueDate.format('YYYY-MM-DD'),
            ignore: 1,
            id: selectedItem.id,
            reason: duePayload.dueReason,
            product_type: '人才银行',
            extend_type: duePayload.dueType,
            adm_mobile: selectedItem.adm_mobile,
        });
        if (d === null || d.data === null) {
            return false;
        }
        const data = d.data;
        if (data === true) {
            return true;
        } else {
            return data.msg || false;
        }
    };
    // 修改权益
    public modifyEquality = async (
        selectedItem: IMemberListStagingData,
        modifyPayload: {
            talentLibVersion: string | number;
            attentionCompanyNum: string | number;
            coIdentity: string | number;
            reachLimit: string | number;
            directOppo: string | number;
            directOppoMonthBalance: string | number;
            addFrMonthBalance: string | number;
            teamName: string | number;
            proNum: string | number;
            recruitLicProNum: string | number;
            subAdmNum: string | number;
            super_exposure: string | number;
            mappings_and_dynamic_switch: string | number;
            jobs_limit: string | number;
            old_ordinary_position: string | number;
            license_gold_medal_position: string | number;
            admin_gold_medal_position: string | number;
            aiTicketNum: string | number;
            aiAccountNum: string | number;
            reportProNum: string | number;
            reportNum: string | number;
            goldAccountNum: string | number;
            goldAccountMonthlyBalance: string | number;
            promotionLicenseNum: string | number;
        },
    ) => {
        const url = '/bff/api/rest/member-list-staging/update-equity';
        const payload = {
            co_id: selectedItem.id,
            talent_lib_version: modifyPayload.talentLibVersion,
            attention_company_nbr: modifyPayload.attentionCompanyNum,
            co_identity: Number(modifyPayload.coIdentity),
            reach_nbr_new: modifyPayload.reachLimit,
            direct_oppo_balance: modifyPayload.directOppo,
            direct_oppo_monthly_balance: modifyPayload.directOppoMonthBalance,
            add_fr_monthly_balance: modifyPayload.addFrMonthBalance,
            pro_num: modifyPayload.proNum,
            recruit_lic_pro_num: modifyPayload.recruitLicProNum,
            team_name: modifyPayload.teamName,
            sub_adm_num: modifyPayload.subAdmNum,
            super_exposure: modifyPayload.super_exposure,
            mappings_and_dynamic_switch:
                modifyPayload.mappings_and_dynamic_switch,
            jobs_limit: modifyPayload.jobs_limit,
            old_ordinary_position: modifyPayload.old_ordinary_position,
            license_gold_medal_position:
                modifyPayload.license_gold_medal_position,
            admin_gold_medal_position: modifyPayload.admin_gold_medal_position,
            ai_ticket_num: modifyPayload.aiTicketNum,
            ai_account_num: modifyPayload.aiAccountNum,
            report_pro_num: modifyPayload.reportProNum,
            report_num: modifyPayload.reportNum,
            gold_account_num: modifyPayload.goldAccountNum,
            gold_account_monthly_balance:
                modifyPayload.goldAccountMonthlyBalance,
            promotion_license_num: modifyPayload.promotionLicenseNum,
        };
        const [d, e] = await defaultAxios.post(url, payload);
        if (d === null || d.data === null) {
            return false;
        }
        const data = d.data;
        if (data === true) {
            return true;
        } else {
            return data.msg || false;
        }
    };
    // 修改cid
    public modifyCid = async (
        selectedItem: IMemberListStagingData,
        cid: number,
    ) => {
        const url = '/crm/member/updateCid';
        const payload = {
            b_mbr_co_id: selectedItem.id,
            new_cid: cid,
            cid: selectedItem.cid,
            adm_mobile: selectedItem.adm_mobile,
        };
        const [d, e] = await defaultAxios.post(url, payload);
        if (d === null || d.data === null) {
            return false;
        }
        const data = d.data;
        if (data === true) {
            return true;
        } else {
            return data.msg || false;
        }
    };
    // 分配子账号权益
    public distributeChildEquality = async (
        items: IMemberListStagingChildData,
        type: number,
        equality: {
            addFrNum?: number;
            phoneCallNum?: number;
            reason?: string;
        },
    ) => {
        // tslint:disable-next-line:variable-name
        const license_id = items ? items.id : '';
        const payload = {
            new_reach_nbr: equality.phoneCallNum,
            new_add_fr_v2tov3: equality.addFrNum,
            op_reason: equality.reason,
            license_id,
        };
        const url =
            '/bff/api/rest/member-list-staging/distribute-license-equity';
        const [d, e] = await defaultAxios.post(url, payload);
        if (d === null || d.data === null) {
            return false;
        }
        const data = d.data;
        if (data === true) {
            return true;
        } else {
            return data?.msg || false;
        }
    };
    // 人才库开关
    public switchTalentPool = async (
        selectedItem: IMemberListStagingData,
        status: boolean,
    ) => {
        const url = '/bff/api/rest/member-list-staging/talent-pool-switch';
        const payload = {
            co_id: selectedItem.id,
            is_open: status ? 1 : 0,
        };
        const [d, e] = await defaultAxios.post(url, payload);
        if (d === null || d.data === null) {
            return false;
        }
        const data = d.data;
        if (data === true) {
            return true;
        } else {
            return data.msg || false;
        }
    };
    public preRetrieveSeat = async (
        selectedSeat: IMemberListStagingChildData,
        cid: string | number,
    ) => {
        const url = '/bff/api/rest/member-list-staging/retrieve-seat';
        const payload = {
            lic_id: selectedSeat.id,
            role_id: selectedSeat.role_id.join(','),
            co_id: cid,
            op_type: 1,
        };
        const [d, e] = await defaultAxios.post(url, payload);
        if (d === null || d.data === null) {
            return false;
        }
        const data = d.data;
        return data?.cnt || 0;
    };
    // 回收席位
    public retrieveSeat = async (selectedSeat: IMemberListStagingChildData) => {
        const url = '/bff/api/rest/member-list-staging/retrieve-seat';
        const payload = {
            lic_id: selectedSeat.id,
            role_id: selectedSeat.role_id.join(','),
            op_type: 2,
        };
        const [d, e] = await defaultAxios.post(url, payload);
        if (d === null || d.data === null) {
            return false;
        }
        const code = d.data.code;
        if (code == 0) {
            return true;
        } else {
            return d.data.msg || false;
        }
    };
    // 启用、禁用席位
    public switchSeatStatus = async (
        selectedSeats: IMemberListStagingChildData[],
        isOpen: boolean,
    ) => {
        const url = '/bff/api/rest/member-list-staging/switch-seat-status';
        const payload = {
            lic_ids: selectedSeats
                .map(item => {
                    return item.id;
                })
                .join(','),
            op_type: isOpen ? 1 : 2,
        };
        const [d, e] = await defaultAxios.post(url, payload);
        if (d === null || d.data === null) {
            return false;
        }
        const data = d.data;
        if (data === true) {
            return true;
        } else {
            return data.msg || false;
        }
    };
    public unbindGoldMobile = async (
        selectedSeat: IMemberListStagingChildData,
    ) => {
        const url = '/crm/member/licensePermission';
        const payload = {
            unbindGoldMobile: 1,
            licenseId: selectedSeat.id,
        };

        const [d, e] = await defaultAxios.post(url, payload);
        if (d === null || d.data === null) {
            return false;
        }

        const data = d.data;
        if (data === true) {
            return true;
        } else {
            return data.msg || false;
        }
    };
}
export const getDefaultMemberListStagingStore = createDefaultInstanceGetter(
    MemberListStagingStore,
);
