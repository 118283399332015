import React, { useContext, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { TableLv2 } from '@/components';
import { Icon, message, Modal, Popover } from 'antd';
import { defaultAxios } from '@/utils';
import { StoreContext } from '@/stores';
import { useRequest } from 'ahooks';
import moment from 'moment';
import { getValueOrDefault } from '@/utils/helpers';
import { tryNewCall } from '@/containers/hoc/call-center';
import { CtxProvider } from '@/utils/context';
import { CUser2Contacts } from '@/components/common/mai-trend/cuser-modal/cuser2contacts';
import Ccpopover from '@/components/common/cc-popover';

export interface IJobTableProps {
    company: string;
    queryScene: string;
    cid?: string | number;
    isPrivateCus: boolean;
    customerOriginData: any;
}

async function getInnerJobDetail(cid: string | number, queryScene: string) {
    const api = '/crm/customer/mai/maimai/jobdetails';
    const params = {
        cid,
        queryScene,
    };

    const [d, e] = await defaultAxios.put(api, params);

    if (d === null || d.data === null) {
        return;
    }
    return d.data;
}

const JobTable: React.FC<IJobTableProps> = ({
    company,
    cid,
    queryScene,
    isPrivateCus,
    customerOriginData,
}) => {
    const store = useContext(StoreContext);
    const [popoverVisable, setPopoverVisable] = useState(false);
    const cUserStore = store.getDefaultCUserStore();
    const {
        getRemainDecodeNumber,
        decodeCUser,
        checkCUserDecoded,
        // maiTrendContactTrigger,
        // setMaiTrendContactTrigger,
        maiTrendContactCurrentData,
        setMaiTrendContactCurrentData,
    } = cUserStore;

    const [maiTrendContactTrigger, setMaiTrendContactTrigger] = useState(0);

    const { data, loading } = useRequest(
        () => getInnerJobDetail(cid!, queryScene),
        {
            refreshDeps: [cid, queryScene],
            ready: !!cid && Number(cid) > 0,
        },
    );
    const onVisibleChange = (visable: any) => {
        console.log('vvvvisible', visable);
        setPopoverVisable(visable);
    };
    const decodePhoneAndT2Contract = async (id: number, oData: any) => {
        const [d, e] = await checkCUserDecoded(id);
        if (d) {
            // 已解密
            const [data, error] = await decodeCUser(id, true);
            if (data) {
                // 拉起转联系人
                const transData = {
                    uid: id,
                    username: oData.hrName,
                    position: oData.hrPosition,
                    realname: oData.hrName,
                    company,
                    call: true,
                    phone: data,
                    loginTime: '',
                    profileUrl: '',
                };
                setMaiTrendContactTrigger(maiTrendContactTrigger + 1);
                setMaiTrendContactCurrentData({
                    ...transData,
                    decodedPhone: data,
                });
            }
        } else {
            if (cUserStore.remainCount <= 0) {
                message.warning(
                    '为了防止C端用户信息泄漏，您每天只有200次解密手机号的机会，目前机会已用完！',
                );
                return;
            }
            // 未解密
            Modal.confirm({
                content: (
                    <div>
                        <p>本次转联系人，将消耗1次机会</p>
                        <p>
                            您今天剩余可用机会为
                            <span style={{ color: 'red' }}>
                                {cUserStore.remainCount}
                            </span>
                            次，请确认是否转联系人？
                        </p>
                    </div>
                ),
                onOk: async () => {
                    const [data, error] = await decodeCUser(id, true);
                    if (data) {
                        // 拉起外呼
                        getRemainDecodeNumber();
                        // 拉起转联系人
                        const transData = {
                            uid: id,
                            username: oData.hrName,
                            position: oData.hrPosition,
                            realname: oData.hrName,
                            company,
                            call: true,
                            phone: data,
                            loginTime: '',
                            profileUrl: '',
                        };
                        setMaiTrendContactTrigger(maiTrendContactTrigger + 1);
                        setMaiTrendContactCurrentData({
                            ...transData,
                            decodedPhone: data,
                        });
                    }
                },
            });
        }
    };

    const decodePhoneHandle = async (
        id: string,
        name: string,
        position: string,
    ) => {
        const [d, e] = await checkCUserDecoded(id);
        if (d) {
            // 已解密
            const [data, error] = await decodeCUser(id, true);
            if (data) {
                // 拉起外呼

                tryNewCall(data, true, {
                    customerName: company,
                    customerId: customerOriginData.id,
                    showFollowLog: true,
                    userName: name,
                    userPosition: position,
                });
            }
        } else {
            if (cUserStore.remainCount <= 0) {
                message.warning(
                    '为了防止C端用户信息泄漏，您每天只有200次外呼手机号的机会，目前机会已用完！',
                );
                return;
            }
            // 未解密
            Modal.confirm({
                content: (
                    <div>
                        <p>本次外呼，将消耗1次机会</p>
                        <p>
                            您今天剩余可用机会为
                            <span style={{ color: 'red' }}>
                                {cUserStore.remainCount}
                            </span>
                            次，请确认是否外呼？
                        </p>
                    </div>
                ),
                onOk: async () => {
                    const [data, error] = await decodeCUser(id, true);
                    if (data) {
                        // 拉起外呼

                        getRemainDecodeNumber();
                        tryNewCall(data, true, {
                            customerName: company,
                            customerId: customerOriginData.id,
                            showFollowLog: true,
                            userName: name,
                            userPosition: position,
                        });
                    }
                },
            });
        }
    };

    return (
        <>
            <CtxProvider
                ctx={{
                    position: 'drawer',
                    positionDetail: 'customer-drawer-maitrend-cuser',
                    data: { cid: customerOriginData?.id },
                }}
            >
                <CUser2Contacts
                    trigger={maiTrendContactTrigger}
                    customerInfo={customerOriginData}
                    cuser={maiTrendContactCurrentData}
                />
            </CtxProvider>
            <TableLv2<any>
                dataSource={data}
                loading={loading}
                columns={[
                    {
                        title: '职位',
                        width: '120px',
                        dataIndex: 'jobPosition',
                        fixed: true,
                        render: (text, record) => {
                            return (
                                <a href={record.jobUrl} target="_blank">
                                    {text}
                                </a>
                            );
                        },
                    },
                    {
                        title: '薪酬范围',
                        key: 'salary',
                        width: '100px',
                        render(v: any, item: any) {
                            item = item || {};
                            const { jobMaxSalary, jobMinSalary } = item;
                            if (!jobMaxSalary && !jobMinSalary) {
                                return '--';
                            }
                            return `${`${Math.round(
                                Number(jobMinSalary) / 1000,
                            )}k` || '--'} ~ ${`${Math.round(
                                Number(jobMaxSalary) / 1000,
                            )}k` || '--'}`;
                        },
                    },
                    {
                        title: '工作地址',
                        width: '100px',
                        dataIndex: 'jobAddress',
                        render: text => getValueOrDefault(text),
                    },
                    {
                        title: '发布时间',
                        width: '120px',
                        dataIndex: 'pubDate',
                        render: text => {
                            return text
                                ? moment(text).format('YYYY-MM-DD')
                                : '-';
                        },
                        sorter: (a, b) => a.pubDate - b.pubDate,
                    },
                    {
                        title: '发布人',
                        width: '100px',
                        dataIndex: 'hrName',
                        render: (text, record) => {
                            return (
                                <span>
                                    <a
                                        href={record.hrProfileUrl}
                                        target="_blank"
                                    >
                                        {text}
                                    </a>{' '}
                                    {isPrivateCus ? (
                                        <Popover
                                            getPopupContainer={() => {
                                                return document.body as HTMLElement;
                                            }}
                                            onVisibleChange={onVisibleChange}
                                            placement="right"
                                            content={
                                                <Ccpopover
                                                    uid={
                                                        popoverVisable
                                                            ? record.hrUid
                                                            : undefined
                                                    }
                                                    whetherShow={false}
                                                />
                                            }
                                        >
                                            <Icon
                                                onClick={() => {
                                                    decodePhoneHandle(
                                                        record.hrUid,
                                                        record.hrName,
                                                        record.hrPosition,
                                                    );
                                                }}
                                                style={{ cursor: 'pointer' }}
                                                type="phone"
                                                theme="twoTone"
                                                twoToneColor="#0052ff"
                                            />
                                        </Popover>
                                    ) : null}
                                </span>
                            );
                        },
                    },
                    {
                        title: '发布人职位',
                        width: '120px',
                        dataIndex: 'hrPosition',
                    },
                    {
                        title: '发布人最后活跃时间',
                        dataIndex: 'lastActiveDate',
                        width: 160,
                        render: text => {
                            return text
                                ? moment(text).format('YYYY-MM-DD')
                                : '-';
                        },
                        sorter: (a, b) => a.lastActiveDate - b.lastActiveDate,
                    },
                    {
                        title: '职位曝光数',
                        dataIndex: 'jobShowCnt',
                        width: 80,
                        render: text => getValueOrDefault(text),
                    },
                    {
                        title: '职位访问数',
                        dataIndex: 'jobVisitorCnt',
                        width: 80,
                        render: text => getValueOrDefault(text),
                    },
                    {
                        title: '投递简历数',
                        dataIndex: 'jobResumeCnt',
                        width: 80,
                        render: text => getValueOrDefault(text),
                    },
                    {
                        title: '通过初筛数',
                        width: 80,
                        dataIndex: 'passScreeningCnt',
                        render: text => getValueOrDefault(text),
                    },
                ]}
                adjustHeight={false}
                scroll={{
                    y: 180,
                    x: 1000,
                }}
                actionsWidth={80}
                actions={[
                    {
                        actionKey: 'transferContact',
                        actionCn: '转联系人',
                    },
                ]}
                onAction={(actionKey, data) => {
                    if (actionKey === 'transferContact') {
                        console.log('jjjjjjjj');
                        decodePhoneAndT2Contract(data.hrUid, data);
                    }
                }}
                pagination={false}
                isFixedActions={false}
                closeRowSelection={true}
            />
        </>
    );
};
export default observer(JobTable);
