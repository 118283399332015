import React from 'react';
import { observer } from 'mobx-react-lite';
import { TableLv2 } from '@/components';
import { defaultAxios } from '@/utils';
import { useRequest } from 'ahooks';
import moment from 'moment';

export interface ICompeteJobTableProps {
    customerName: string;
    lastDay: number;
}

async function getCompeteJobDetail(
    defaultCustomerName: string,
    lastDay: number,
) {
    const api = '/crm/customer/mai/compete/jobdetails';
    const params = {
        customerName: defaultCustomerName,
        competeCompany: 'ALL',
        highSalary: false,
        lastDay,
    };

    const [d, e] = await defaultAxios.put(api, params);

    if (d === null || d.data === null) {
        return;
    }
    return d.data;
}

const CompeteJobTable: React.FC<ICompeteJobTableProps> = ({
    customerName,
    lastDay,
}) => {
    const { data, loading } = useRequest(
        () => getCompeteJobDetail(customerName, lastDay),
        { refreshDeps: [customerName, lastDay] },
    );

    return (
        <TableLv2<any>
            dataSource={data}
            loading={loading}
            adjustHeight={false}
            scroll={{
                y: 180,
                x: 1000,
            }}
            columns={[
                {
                    title: '竞品',
                    width: 60,
                    dataIndex: 'competitionName',
                },
                {
                    title: '职位名称',
                    dataIndex: 'title',
                },
                {
                    title: '薪资',
                    dataIndex: 'maxSalary',
                    render(v: any, item: any) {
                        item = item || {};
                        const { maxSalary, minSalary } = item;
                        if (!maxSalary && !minSalary) {
                            return '--';
                        }
                        return `${`${Math.round(Number(minSalary) / 1000)}k` ||
                            '--'} ~ ${`${Math.round(
                            Number(maxSalary) / 1000,
                        )}k` || '--'}`;
                    },
                },
                {
                    title: '分类',
                    dataIndex: 'classification',
                },
                {
                    title: '职位发布时间',
                    dataIndex: 'pubDate',
                    render: text => {
                        return text ? moment(text).format('YYYY-MM-DD') : '-';
                    },
                    sorter: (a, b) => a.pubDate - b.pubDate,
                },
                {
                    title: '职位链接',
                    dataIndex: 'jobLink',
                    width: 60,
                    render(v: any) {
                        if (!v) {
                            return null;
                        }
                        return (
                            <a target="_blank" href={v}>
                                查看
                            </a>
                        );
                    },
                },
            ]}
            pagination={false}
            closeRowSelection={true}
        />
    );
};

export default observer(CompeteJobTable);
