import React, {
    memo,
    useEffect,
    useContext,
    useState,
    useCallback,
} from 'react';
import {
    DForm,
    IProcessCtx,
    IDFormProps,
    AntTable,
    applyConfToFormItem,
} from '@/components';
import _, { isArray } from 'lodash';
import { observer } from 'mobx-react-lite';
import { ProcessCatStore } from '../../store';
import {
    arrToJsonPrimary,
    defaultAxios,
    isNetSuccess,
    arrToJson,
    makeStringValidator,
    useRefresh,
} from '@/utils';
import { validators } from '@/pages/demo2/form2/options';
import { getValidatorByParamMeta } from '@/stores/utils';
import { applyCustomConfToWidget } from '@/components/common/form/advanced-filters';
import {
    talentBankVersion2ParamWhiteList,
    talentBankVersion3ParamWhiteList,
    talentBankVersionWorkbenchParamWhiteList,
    talentBankVersionWorkbenchParamWhiteArr,
    changeAdminGetRequiredAndHiddenParams,
    openProductChangeProductOpenTypeText,
} from '../utils';
import { CtxContext } from '@/utils/context';
import { Alert, message, Icon } from 'antd';
import { seeContractProcess } from '@/pages/om/contract/list/store';
import { commonQuery } from '@/api/rest';
import querystring from 'querystring';
import { StoreContext } from '@/stores';
import { CustomerContactsCreate } from '@/components/common/customer-drawer/customer-contacts-create';

const openAdminSuffixes = ['', '2', '3', '4', '5'];

const DemoForm: React.FC<{
    defaultStore: ProcessCatStore;
    processCtx: IProcessCtx | null;
    tableCode: string | null;
    mode: 'create' | 'update';
}> = ({ defaultStore, processCtx, tableCode, mode }) => {
    const ctx = useContext(CtxContext);
    const { position, positionDetail } = ctx;
    const isCreate = mode === 'create';
    const store = useContext(StoreContext);
    const authStore = store.getAuthStore();
    const sysId = authStore?.userInfo?.sysUser?.id;

    const { defaultMeta, mutatingData: muData } = defaultStore;
    const [fillingLoading, setFillingLoading] = useState(false);

    const [createContactsTrigger, setCreateContactsTrigger] = useState(0);
    const [contactsRefreshTrigger, setContactsRefreshTrigger] = useState(0);
    const updateContactsRefreshTrigger = useCallback(() => {
        setContactsRefreshTrigger(contactsRefreshTrigger + 1);
    }, [contactsRefreshTrigger]);
    useRefresh(['process-contacts'], updateContactsRefreshTrigger);

    const [
        fillContractProductOpenLoading,
        setFillContractProductOpenLoading,
    ] = useState(false);

    const [subCuslistClearTrigger, setSubCusListClearTrigger] = useState(0);
    const [subCusLoading, setSubCusLoading] = useState(false);
    const product_type = muData?.product_type;
    const talent_bank_version = muData?.talent_bank_version;
    const product_open_type = muData?.product_open_type;
    const product_open_team = muData?.product_open_team;
    const isWorkbench = product_type === 1 && talent_bank_version === 2;
    const isZgxy = new Set([2, 3]).has(product_open_type);
    const isZg = new Set([3]).has(product_open_type);

    const isApply =
        defaultMeta?.processAllInfo?.crmActivity?.activityId === 'open_apply';
    const isFinal = _.startsWith(
        defaultMeta?.processAllInfo?.crmActivity?.activityId || '',
        'business_operate',
    );
    const isBkFinal =
        defaultMeta?.processAllInfo?.crmActivity?.activityId ===
        'business_operate_tb';

    const [
        selectedProductionOpenNameCidDiff,
        setSelectedProductionOpenNameCidDiff,
    ] = useState<boolean>(false);

    useEffect(() => {
        setSelectedProductionOpenNameCidDiff(false);
        // 工作台 & 续约or增购
        if (!isZgxy) {
            return;
        }

        // v2/v3 和 工作台取值不同
        const productOpenAdminPhone = isWorkbench
            ? muData?.product_open_admin_phone
            : muData?.product_open_administrator_phone;

        const openCusName = muData?.open_customer_id?.relatedObj?.customer_name;
        // 如果在这两个阶段，且存在一个openTeam
        if ((isApply || isFinal) && !!productOpenAdminPhone && !!openCusName) {
            defaultAxios
                .get('/crm/workflow/contractproduct/checkcid', {
                    mobile: productOpenAdminPhone,
                    cname: openCusName,
                })
                .then(([d, e]) => {
                    if (isNetSuccess(d, e) && _.isBoolean(d?.data)) {
                        setSelectedProductionOpenNameCidDiff(!d?.data);
                    }
                });
        }
    }, [
        isWorkbench,
        isZgxy,
        isApply,
        isFinal,
        muData?.product_open_admin_phone,
        muData?.product_open_administrator_phone,
        muData?.open_customer_id,
    ]);

    const showCidDiffLint =
        isZgxy &&
        (isApply || isFinal) &&
        (isWorkbench
            ? !!muData?.product_open_admin_phone
            : !!muData?.product_open_administrator_phone) &&
        selectedProductionOpenNameCidDiff;

    const cidDiffLint =
        showCidDiffLint && (isApply || isFinal)
            ? isApply
                ? '当前管理员归属的公司CID和本次开通客户的CID不一致，请确认是否需要变更？'
                : '当前管理员归属的公司CID和本次开通客户的CID不一致，请确认是否需要变更？'
            : '';

    useEffect(() => {
        if (!muData) {
            return;
        }
        // 挂个字段
        if (!selectedProductionOpenNameCidDiff || !cidDiffLint) {
            delete muData.cidDiffLint;
            return;
        }
        muData.cidDiffLint = cidDiffLint;
    }, [muData, cidDiffLint, selectedProductionOpenNameCidDiff]);

    const {
        customerTeamListLoading,
        isCustomerWithTeamList,
        customerTeamList,
        workbenceStatistics,
    } = defaultStore;
    const noProductOpenType = !product_open_type;
    const selectedTeamItem = customerTeamList.find(
        item => item.id === product_open_team,
    );
    const teamNoName = !!selectedTeamItem && !selectedTeamItem.originalTeamName;

    // 控制用枚举还是文本框
    // product_open_type
    // customerTeamListLoading, isCustomerWithTeamList, customerTeamList

    const useText = teamNoName || product_open_type === 1; // 选择了一个没有名字的团队 // 新签

    // 续约增购始终需要选择
    const isZenggouxuyue = isZgxy;
    const useSelect = isZenggouxuyue;
    const isProductOpenTeamHidden =
        !isWorkbench ||
        noProductOpenType ||
        customerTeamListLoading ||
        !useSelect;
    const isProductOpenTeamNameHidden =
        !isWorkbench ||
        noProductOpenType ||
        customerTeamListLoading ||
        !useText;

    // 如果选择了一个团队，则回填信息
    useEffect(() => {
        if (!isWorkbench) {
            return;
        }
        if (!selectedTeamItem) {
            return;
        }
        defaultAxios
            .get('/crm/workflow/contractproduct/teamadmin', {
                coid: selectedTeamItem.id,
            })
            .then(([d, e]) => {
                if (d?.data) {
                    // "admUid":1123123,"admName":"张三","mobile":"13311112222"
                    muData.product_open_admin = d.data.adm_name;
                    muData.product_open_admin_phone = d.data.mobile;
                }
            });
        // muData.product_open_admin = '狗蛋';
        // muData.product_open_admin_phone = '13211223300';
    }, [selectedTeamItem, isWorkbench]);

    // 算开通基础信息布局
    const [
        productOpenAdminBasicGroupLayout,
        setProductOpenAdminBasicGroupLayout,
    ] = useState<any[]>([]);
    useEffect(() => {
        const activityFields =
            defaultMeta?.processAllInfo?.crmActivity?.activityFields;
        if (!defaultMeta?.objectMeta?.length || !_.isArray(activityFields)) {
            return;
        }
        const activityFieldsMap = arrToJson(activityFields, 'fieldCode');

        // cid重复
        // /crm/workflow/contractproduct/companyrelated?name=

        const layout: any[] = [];
        // magic...
        for (const metaItem of defaultMeta?.objectMeta || []) {
            if (metaItem.groupKey === '32_open') {
                if (
                    !activityFieldsMap[metaItem.fieldName] ||
                    activityFieldsMap[metaItem.fieldName].isView !== 1
                ) {
                    continue;
                }
                if (
                    metaItem.fieldName === 'product_open_team' &&
                    isProductOpenTeamHidden
                ) {
                    continue;
                }
                if (
                    metaItem.fieldName === 'product_open_team_name' &&
                    isProductOpenTeamNameHidden
                ) {
                    continue;
                }
                if (
                    metaItem.fieldName === 'talent_bank_version' &&
                    muData?.product_type === 0
                ) {
                    continue;
                }
                if (
                    metaItem.fieldName === 'enterprise_version' &&
                    muData?.product_type === 1
                ) {
                    continue;
                }
                if (metaItem.fieldName === 'product_open_administrator') {
                    layout.push(
                        [8, 8, 8],
                        [8, 8, 8],
                        [8, 8, 8],
                        [8, 8, 8],
                        [8, 8, 8],
                        [8, 8, 8],
                    );
                    break;
                }
                layout.push([24]);
            }
        }
        setProductOpenAdminBasicGroupLayout(layout);
    }, [
        defaultMeta?.processAllInfo,
        defaultMeta?.objectMeta,
        muData?.product_type,
        isProductOpenTeamHidden,
        isProductOpenTeamNameHidden,
    ]);

    // 加载客户列表
    useEffect(() => {
        if (!isWorkbench) {
            return;
        }
        if (!(muData?.open_customer_cid || muData?.customer_cid)) {
            return;
        }
        defaultStore.fetchCustomerTeamList(
            muData?.open_customer_cid || muData?.customer_cid,
        );
    }, [muData?.customer_cid, muData?.open_customer_cid, isWorkbench]);

    // 加载权益统计信息
    useEffect(() => {
        if (!isWorkbench) {
            return;
        }
        defaultStore.fetchWorkbenceStatistics(muData?.contract_product_id);
    }, [muData?.contract_product_id, isWorkbench]);

    const [companyRelated, setCompanyRelated] = useState<any>(null);
    const hasOpenCustomerCid = !!muData?.open_customer_id;
    useEffect(() => {
        const name = muData?.customer_id?.relatedObj?.customer_name;
        const openCusName = muData?.open_customer_id?.relatedObj?.customer_name;
        if (muData?.product_type !== 0) {
            return;
        }
        if (!name && !openCusName) {
            return;
        }

        // cid重复
        setCompanyRelated(null);
        defaultAxios
            .get('/crm/workflow/contractproduct/companyrelated', {
                name: openCusName || name,
            })
            .then(([d, e]) => {
                if (
                    isNetSuccess(d, e) &&
                    _.isArray(d?.data) &&
                    d?.data.length > 0
                ) {
                    setCompanyRelated(d?.data);
                } else {
                    setCompanyRelated(null);
                }
            });
    }, [muData?.customer_id, muData?.open_customer_id, muData?.product_type]);

    if (!defaultMeta) {
        return null;
    }
    const baiscWidgets = defaultMeta.defaultWidgetsAllWithoutGroupCheck;

    const collapseLayout = defaultMeta.defaultFormCollapseLayout;
    const shouldGroup = collapseLayout.length > 0;

    const layout = new Array(Math.ceil(baiscWidgets.length / 2)).fill([12, 12]);
    if (shouldGroup) {
        for (const colla of collapseLayout) {
            colla.layout = layout;
        }
    }

    if (processCtx === null || !processCtx.currentUser) {
        return null;
    }

    const props: IDFormProps = {
        processCtx,
        defaultStore: defaultStore as any,
        widgetsGenerator: store => {
            return store.defaultMeta.defaultWidgetsAllWithoutGroupCheck;
        },
    };

    if (shouldGroup) {
        props.options = {
            grouped: true,
        };
    } else {
        props.propsTransformer = props => {
            props.layout = layout;
            return props;
        };
    }

    const { mutatingData } = defaultStore;

    let extraContent: React.ReactNode = null;

    // 如果是开通申请
    if (mutatingData !== null) {
        const { objectMetaMap, defaultWidgetLabelsMap } = defaultMeta;

        const inContractProductList =
            position === 'list' && positionDetail === 'contract-product';
        const contractProductListBlackList = new Set([
            'customer_id',
            'opportunity_id',
            'contract_product_id',
            'contract_id',
        ]);

        const cusId = mutatingData?.customer_id?.originalValue;
        const cusName = mutatingData?.customer_id?.relatedObj?.customer_name;

        extraContent = (
            <CustomerContactsCreate
                trigger={createContactsTrigger}
                customerInfo={{
                    id: cusId,
                    customer_name: cusName,
                }}
            />
        );

        props.labelsTransformer = originalLabels => {
            const nextLabels = [...originalLabels];
            nextLabels.forEach(label => {
                // 邮箱统一布局
                // product_open_administrator_email
                // ['product_open_admin', 'product_open_admin_phone', 'product_open_admin_email']
                if (
                    _.startsWith(
                        label.key as string,
                        'product_open_administrator_email',
                    ) ||
                    label.key === 'product_open_admin_email'
                ) {
                    label.labelWidth = 13;
                }

                // 电话统一布局
                // product_open_administrator_phone
                if (
                    _.startsWith(
                        label.key as string,
                        'product_open_administrator_phone',
                    ) ||
                    label.key === 'product_open_admin_phone'
                ) {
                    label.labelWidth = 15;
                    label.style = { transform: 'translateX(8px)' };
                }

                if (
                    label.key === 'product_open_team' ||
                    label.key === 'product_open_administrator' ||
                    label.key === 'product_open_team_name' ||
                    label.key === 'enterprise_display_name' ||
                    label.key === 'operate_duration'
                ) {
                    label.required = true;
                }
            });
            return nextLabels;
        };

        props.validatorsTransformer = validators => {
            for (const k of [
                'product_open_team',
                'product_open_team_name',
                'product_open_administrator',
                'enterprise_display_name',
            ]) {
                const paramsMeta = _.cloneDeep(objectMetaMap[k]);
                paramsMeta.isRequired = true;
                validators[k] = getValidatorByParamMeta(paramsMeta);
            }

            const paramsMeta = _.cloneDeep(objectMetaMap['operate_duration']);
            paramsMeta.isRequired = true;
            validators['operate_duration'] = getValidatorByParamMeta(
                paramsMeta,
            );

            return validators;
        };

        const originalOnChange = props.onChange;
        props.onChange = (key, value, widgetKey) => {
            if (key === 'contract_product_id') {
                const contractProductId = value?.originalValue;
                if (contractProductId) {
                    setFillContractProductOpenLoading(true);
                    defaultAxios
                        .get('/bff/api/rest/process/fillContractProductOpen', {
                            contractProductId,
                        })
                        .then(([d, e]) => {
                            setFillContractProductOpenLoading(false);
                            if (isNetSuccess(d, e)) {
                                const datum = d?.data?.datas?.[0];
                                delete datum.contract_product_id;
                                if (datum) {
                                    _.assign(mutatingData, datum);
                                }
                            }
                        });
                }
            }

            const tmpk = key as string;

            if (tmpk === 'open_customer_id') {
                mutatingData.open_customer_cid = null;
                commonQuery(
                    'customer',
                    {
                        pageSize: 1,
                        pageNum: 1,
                    },
                    {
                        filters: [
                            {
                                fieldId: 'id',
                                filterValue: value?.originalValue,
                                operateType: '=',
                            },
                        ],
                    },
                    undefined,
                    undefined,
                    undefined,
                    'SEARCH-ALL',
                ).then(([d, e]) => {
                    if (_.isNil(d) || e !== null) {
                        return;
                    }
                    const { datas } = d;
                    if (datas && datas[0]) {
                        const dt = datas[0];
                        mutatingData.open_customer_cid = dt.customer_cid;
                        mutatingData.enterprise_display_name = dt.customer_name;
                    } else {
                    }
                });
            }

            if (_.startsWith(tmpk, 'product_open_administrator')) {
                const suffix = tmpk.substring(
                    'product_open_administrator'.length,
                );
                mutatingData[
                    'product_open_administrator_phone' + suffix
                ] = null;
                mutatingData[
                    'product_open_administrator_email' + suffix
                ] = null;
                commonQuery(
                    'contacts',
                    {
                        pageSize: 1,
                        pageNum: 1,
                    },
                    {
                        filters: [
                            {
                                fieldId: 'id',
                                filterValue: value?.originalValue,
                                operateType: '=',
                            },
                        ],
                    },
                    undefined,
                    undefined,
                    undefined,
                    'SEARCH-ALL',
                ).then(([d, e]) => {
                    setSubCusLoading(false);
                    if (_.isNil(d) || e !== null) {
                        return;
                    }
                    const { datas } = d;
                    if (datas && datas[0]) {
                        const dt = datas[0];
                        mutatingData[
                            'product_open_administrator_phone' + suffix
                        ] = dt.contacts_phone;
                        mutatingData[
                            'product_open_administrator_email' + suffix
                        ] = dt.contacts_work_email;
                    } else {
                    }
                });
            }

            if (key === 'customer_id' || key === 'open_customer_id') {
                for (const suffix of openAdminSuffixes) {
                    mutatingData['product_open_administrator' + suffix] = null;
                    mutatingData[
                        'product_open_administrator_phone' + suffix
                    ] = null;
                    mutatingData[
                        'product_open_administrator_email' + suffix
                    ] = null;
                }
            }

            if (originalOnChange) {
                return originalOnChange(key, value, widgetKey);
            }
        };

        const {
            product_type,
            talent_bank_version,
            product_open_type,
            product_open_team,
        } = mutatingData;

        const groupsToHide: Set<string> = new Set();
        if (product_type === 1) {
            // 人才银行
            // 32_bank
            groupsToHide.add('32_enterprise');
            groupsToHide.add('32_tuoketong');

            const { talent_bank_version } = mutatingData;
            let whiteList = new Set<string>(['talent_bank_version']);
            if (talent_bank_version === 1) {
                // 人才银行
                // v2
                whiteList = talentBankVersion2ParamWhiteList;
            } else if (talent_bank_version === 0) {
                // 人才银行
                // v3
                whiteList = talentBankVersion3ParamWhiteList;
            } else {
                // 工作台
                whiteList = talentBankVersionWorkbenchParamWhiteList;
            }
            const { defaultGroupReverseMap, defaultGroupParams } = defaultMeta;
            props.widgetsTransformer = originalWidgets => {
                const nextWidgets = [...originalWidgets];
                applyConfToFormItem(nextWidgets, 'inputSecret', {
                    sceneCode: 'crm_product_open',
                });
                nextWidgets.forEach(widget => {
                    const colKey = widget.key as string;
                    if (
                        defaultGroupReverseMap[widget.key as string] ===
                        '32_bank'
                    ) {
                        if (!whiteList.has(colKey)) {
                            widget.status = widget.status || {};
                            widget.status.hidden = true;
                        } else {
                            widget.status = widget.status || {};
                            widget.status.hidden = false;
                        }
                    }

                    // 展示统计
                    if (
                        talentBankVersionWorkbenchParamWhiteList.has(
                            widget.key as string,
                        )
                    ) {
                        const curStatistic =
                            workbenceStatistics[widget.key as string];
                        if (
                            talent_bank_version === 2 &&
                            _.isNumber(curStatistic)
                        ) {
                            widget.nextlineWidget = () => {
                                const label =
                                    defaultWidgetLabelsMap[
                                        widget.key as string
                                    ];
                                if (_.isNil(label)) {
                                    return (
                                        <Alert
                                            style={{ marginTop: 6 }}
                                            showIcon
                                            type="warning"
                                            message={
                                                <span>
                                                    剩余可用{' '}
                                                    <span
                                                        style={{ color: 'red' }}
                                                    >
                                                        {curStatistic}
                                                    </span>
                                                </span>
                                            }
                                        />
                                    );
                                }
                                return (
                                    <Alert
                                        style={{ marginTop: 6 }}
                                        showIcon
                                        type="warning"
                                        message={
                                            <span>
                                                剩余可用-{label.label}数：{' '}
                                                <span style={{ color: 'red' }}>
                                                    {curStatistic}
                                                </span>
                                            </span>
                                        }
                                    />
                                );
                            };
                        } else {
                            widget.nextlineWidget = () => null;
                        }
                    }
                });

                const openTeamLint = () => (
                    <Alert
                        style={{ marginTop: 6 }}
                        showIcon
                        type="warning"
                        message="工作台产品，支持按团队分批开通，分别到期。【开通新团队】时，请直接输入对应新团队名称；否则，请选择之前已开团队。"
                    />
                );

                applyCustomConfToWidget(
                    nextWidgets,
                    'operate_duration',
                    widget => {
                        const nextWidget = _.cloneDeep(widget);
                        if (isZg) {
                            nextWidget.status = {
                                ...nextWidget.status,
                                hidden: isZg,
                            };
                        }
                        return nextWidget;
                    },
                );

                applyCustomConfToWidget(
                    nextWidgets,
                    'workbench_subadmin_num',
                    widget => {
                        if (!isZg) {
                            widget.status = {
                                ...widget.status,
                                hidden: !isZg,
                            };
                        }
                        return widget;
                    },
                );

                applyCustomConfToWidget(
                    nextWidgets,
                    'product_open_team',
                    widget => {
                        widget.widgetKey = 'select';
                        widget.selectOptions = {
                            options: customerTeamList.map(item => {
                                return {
                                    value: item.id,
                                    name: item.team_name || item.id,
                                };
                            }),
                            placeholder: '选择团队',
                        };
                        widget.status = {
                            ...widget.status,
                            hidden: isProductOpenTeamHidden,
                        };

                        if (isProductOpenTeamNameHidden) {
                            widget.nextlineWidget = openTeamLint;
                        } else {
                            //
                            if (teamNoName) {
                                widget.nextlineWidget = () => {
                                    return (
                                        <Alert
                                            style={{ marginTop: 6 }}
                                            showIcon
                                            type="warning"
                                            message="老版本升级时，团队名称是系统自带的数字，请按客户实际情况在下方输入新的团队名称"
                                        />
                                    );
                                };
                            } else {
                                widget.nextlineWidget = () => null;
                            }
                        }

                        return widget;
                    },
                );

                applyCustomConfToWidget(
                    nextWidgets,
                    'product_open_team_name',
                    widget => {
                        widget.status = {
                            ...widget.status,
                            hidden: isProductOpenTeamNameHidden,
                        };
                        widget.nextlineWidget = openTeamLint;
                        return widget;
                    },
                );

                // 2022.05.23
                // https://maimai.feishu.cn/wiki/wikcnZIX8UE6o69T1SnBNcp4sTc#bbTSvb
                if (!isBkFinal) {
                    [
                        'product_type',
                        'talent_bank_version',
                        ...defaultGroupParams['32_bank'],
                    ].forEach(key => {
                        const whiteListCanChange = [
                            'workbench_recruit_num',
                            'workbench_deptment_num',
                            'workbench_subadmin_num',
                            'workbench_direct_num',
                            'workbench_company_num',
                            'workbench_reach_num',
                            'workbench_ai_ticket_num',
                            'workbench_ai_account_num',
                            'workbench_report_pro_num',
                            'workbench_report_num',
                            'workbench_gold_account_num',
                            'workbench_gold_account_monthly_balance',
                            'workbench_promotion_license_num',
                        ];
                        if (_.indexOf(whiteListCanChange, key) === -1) {
                            applyCustomConfToWidget(
                                nextWidgets,
                                key,
                                widget => {
                                    widget.status = {
                                        ...widget.status,
                                        disabled: true,
                                    };
                                    return widget;
                                },
                            );
                        }
                    });
                }

                return nextWidgets;
            };
        } else if (product_type === 0) {
            // 企业号
            // 32_enterprise
            groupsToHide.add('32_bank');
            groupsToHide.add('32_tuoketong');
            props.widgetsTransformer = originalWidgets => {
                const nextWidgets = [...originalWidgets];
                applyConfToFormItem(nextWidgets, 'inputSecret', {
                    sceneCode: 'crm_product_open',
                });
                applyCustomConfToWidget(
                    nextWidgets,
                    'product_open_team',
                    widget => {
                        widget.status = {
                            ...widget.status,
                            hidden: true,
                        };
                        return widget;
                    },
                );

                applyCustomConfToWidget(
                    nextWidgets,
                    'product_open_team_name',
                    widget => {
                        widget.status = {
                            ...widget.status,
                            hidden: true,
                        };
                        return widget;
                    },
                );

                return nextWidgets;
            };
        } else if (product_type === 4) {
            groupsToHide.add('32_enterprise');
            groupsToHide.add('32_bank');

            props.widgetsTransformer = originalWidgets => {
                const nextWidgets = [...originalWidgets];
                applyCustomConfToWidget(
                    nextWidgets,
                    'product_open_team',
                    widget => {
                        widget.status = {
                            ...widget.status,
                            hidden: true,
                        };
                        return widget;
                    },
                );
                return nextWidgets;
            };
        } else {
            groupsToHide.add('32_enterprise');
            groupsToHide.add('32_bank');
            groupsToHide.add('32_tuoketong');
        }

        // 某些字段不让填
        const oldwidgetsTransformer = props.widgetsTransformer;
        props.widgetsTransformer = prevoriginalWidgets => {
            const originalWidgets = oldwidgetsTransformer
                ? oldwidgetsTransformer(prevoriginalWidgets)
                : prevoriginalWidgets;
            const nextWidgets = [...originalWidgets];
            applyConfToFormItem(nextWidgets, 'inputSecret', {
                sceneCode: 'crm_product_open',
            });
            nextWidgets.forEach(widget => {
                const colKey = widget.key as string;
                if (contractProductListBlackList.has(colKey)) {
                    widget.status = widget.status || {};
                    widget.status.disabled = true;
                }
            });

            applyCustomConfToWidget(nextWidgets, 'open_customer_id', widget => {
                if (widget.objectOptions) {
                    widget.objectOptions.dataAuthCode = 'SEARCH-PRIVATE';
                }
                return widget;
            });

            // 企业号版本option限制
            applyCustomConfToWidget(
                baiscWidgets,
                'enterprise_version',
                nextWidgets => {
                    if (nextWidgets.selectOptions) {
                        const optionsKept = new Set<any>([1, 2, 5]);
                        nextWidgets.selectOptions.options = nextWidgets.selectOptions.options.filter(
                            item => optionsKept.has(item.value),
                        );
                    }
                    nextWidgets.status = {
                        ...nextWidgets.status,
                        hidden: product_type !== 0,
                    };
                    return nextWidgets;
                },
            );

            // 人才银行版本
            applyCustomConfToWidget(
                baiscWidgets,
                'talent_bank_version',
                nextWidgets => {
                    nextWidgets.status = {
                        ...nextWidgets.status,
                        hidden: product_type !== 1,
                    };
                    return nextWidgets;
                },
            );

            applyCustomConfToWidget(
                nextWidgets,
                'product_open_type',
                nextWidget => {
                    openProductChangeProductOpenTypeText(
                        nextWidget,
                        isWorkbench ? 'workbench' : 'v2/3',
                    );
                    return nextWidget;
                },
            );

            applyCustomConfToWidget(
                baiscWidgets,
                'customer_cid',
                nextWidget => {
                    nextWidget.nextlineWidget = () => {
                        return product_type === 0 &&
                            companyRelated &&
                            !hasOpenCustomerCid ? (
                            <div style={{ paddingTop: 4 }}>
                                <Alert
                                    type="warning"
                                    icon={<Icon type="step-forward" />}
                                    message={
                                        <div>
                                            <p
                                                style={{
                                                    color: 'red',
                                                    paddingBottom: 5,
                                                }}
                                            >
                                                当前客户存在多个疑似关联公司，需要合并，请发布【CID合并申请】邮件至qyh@taou.com，提供相关合并企业号cid、合并保留cid信息，成功合并后再回CRM内提交企业号开通。
                                            </p>
                                            <AntTable
                                                dataSource={companyRelated}
                                                columns={[
                                                    {
                                                        title: '客户名称',
                                                        key: 'company',
                                                        dataIndex: 'company',
                                                    },
                                                    {
                                                        title: 'CID',
                                                        key: 'cid',
                                                        dataIndex: 'cid',
                                                    },
                                                ]}
                                                pagination={false}
                                            ></AntTable>
                                        </div>
                                    }
                                />
                            </div>
                        ) : null;
                    };
                    return nextWidget;
                },
            );

            applyCustomConfToWidget(
                baiscWidgets,
                'open_customer_cid',
                nextWidget => {
                    nextWidget.nextlineWidget = () => {
                        let cidDiffLintSnippet: any = null;
                        if (showCidDiffLint && cidDiffLint) {
                            cidDiffLintSnippet = (
                                <div style={{ paddingTop: 4 }}>
                                    <Alert
                                        type="warning"
                                        showIcon
                                        message={<div>{cidDiffLint}</div>}
                                    />
                                </div>
                            );
                        }
                        return product_type === 0 &&
                            companyRelated &&
                            hasOpenCustomerCid ? (
                            <>
                                {cidDiffLintSnippet}
                                <div style={{ paddingTop: 4 }}>
                                    <Alert
                                        type="warning"
                                        icon={<Icon type="step-forward" />}
                                        message={
                                            <div>
                                                <p
                                                    style={{
                                                        color: 'red',
                                                        paddingBottom: 5,
                                                    }}
                                                >
                                                    当前客户存在多个疑似关联公司，需要合并，请发布【CID合并申请】邮件至qyh@taou.com，提供相关合并企业号cid、合并保留cid信息，成功合并后再回CRM内提交企业号开通。
                                                </p>
                                                <AntTable
                                                    dataSource={companyRelated}
                                                    columns={[
                                                        {
                                                            title: '客户名称',
                                                            key: 'company',
                                                            dataIndex:
                                                                'company',
                                                        },
                                                        {
                                                            title: 'CID',
                                                            key: 'cid',
                                                            dataIndex: 'cid',
                                                        },
                                                    ]}
                                                    pagination={false}
                                                ></AntTable>
                                            </div>
                                        }
                                    />
                                </div>
                            </>
                        ) : (
                            cidDiffLintSnippet
                        );
                    };
                    return nextWidget;
                },
            );

            applyCustomConfToWidget(
                baiscWidgets,
                'contract_code',
                nextWidget => {
                    nextWidget.nextlineWidget = () => {
                        return (
                            <div style={{ paddingTop: 4 }}>
                                <a
                                    onClick={() => {
                                        const contractId =
                                            mutatingData?.contract_id
                                                ?.originalValue;
                                        if (contractId) {
                                            seeContractProcess(contractId);
                                        } else {
                                            message.error('合同id不存在');
                                        }
                                    }}
                                >
                                    查看合同
                                    <Icon type="link" />
                                </a>
                            </div>
                        );
                    };
                    return nextWidget;
                },
            );

            applyCustomConfToWidget(
                nextWidgets,
                'contract_product_id',
                widget => {
                    widget.status = {
                        ...widget.status,
                        loading: fillContractProductOpenLoading,
                        disabled: true,
                    };
                    if (widget.objectOptions) {
                        widget.objectOptions.defaultFilters = [
                            {
                                fieldName: 'contract_id',
                                operateType: 'EQUAL',
                                fieldValue:
                                    mutatingData.contract_id?.originalValue,
                            },
                        ];
                    }
                    return widget;
                },
            );

            const showTextAdminInfo =
                isWorkbench && isZenggouxuyue && !!selectedTeamItem;

            for (const k of [
                'product_open_admin',
                'product_open_admin_phone',
                'product_open_admin_email',
            ]) {
                applyCustomConfToWidget(nextWidgets, k, prevwidget => {
                    const widget = _.cloneDeep(prevwidget);
                    let hidden = false;
                    if (showTextAdminInfo) {
                        hidden = false;
                    } else {
                        hidden = true;
                    }
                    widget.status = {
                        ...widget.status,
                        hidden,
                    };
                    return widget;
                });
            }

            for (const suffix of openAdminSuffixes) {
                applyCustomConfToWidget(
                    nextWidgets,
                    'product_open_administrator' + suffix,
                    prevwidget => {
                        const widget = _.cloneDeep(prevwidget);
                        const cusId = mutatingData?.customer_id?.originalValue;
                        const openCusId =
                            mutatingData?.open_customer_id?.originalValue;
                        if (!cusId && !openCusId) {
                            widget.status = {
                                ...widget.status,
                                disabled: true,
                            };
                        }

                        if (product_type !== 0 && suffix === '') {
                            // 人才银行第一组不一定展示
                            if (showTextAdminInfo) {
                                widget.status = {
                                    ...widget.status,
                                    hidden: true,
                                };
                            } else {
                                widget.status = {
                                    ...widget.status,
                                    hidden: false,
                                };
                            }
                        }

                        // 如果是人才银行，则只支持第一组管理员
                        if (product_type !== 0 && suffix !== '') {
                            widget.status = {
                                ...widget.status,
                                hidden: true,
                            };
                        }

                        let defaultFilters: any[] = [];
                        if (openCusId) {
                            defaultFilters = [
                                {
                                    fieldName: 'customer_id',
                                    operateType: 'EQUAL',
                                    fieldValue: openCusId,
                                },
                            ];
                        } else if (cusId) {
                            defaultFilters = [
                                {
                                    fieldName: 'customer_id',
                                    operateType: 'EQUAL',
                                    fieldValue: cusId,
                                },
                            ];
                        }

                        if (widget.objectOptions) {
                            widget.objectOptions.defaultFilters = defaultFilters;
                            widget.objectOptions.reloadTrigger =
                                contactsRefreshTrigger +
                                ':' +
                                cusId +
                                ':' +
                                openCusId;
                        }

                        if (suffix === '' || suffix === '5') {
                            widget.nextlineWidget = () => {
                                if (!cusId || !cusName) {
                                    return null;
                                }
                                // 如果是人才银行，则不显示管理员5的相关jsx
                                if (product_type !== 0 && suffix === '5') {
                                    return null;
                                }
                                // 如果是企业号，则不显示管理员1的相关jsx
                                if (product_type === 0 && suffix === '') {
                                    return null;
                                }
                                return (
                                    <div
                                        onClick={() =>
                                            setCreateContactsTrigger(
                                                1 + createContactsTrigger,
                                            )
                                        }
                                    >
                                        <a>
                                            新建联系人
                                            <Icon type="user" />
                                        </a>
                                    </div>
                                );
                            };
                        }

                        return widget;
                    },
                );

                applyCustomConfToWidget(
                    nextWidgets,
                    'product_open_administrator_phone' + suffix,
                    prevwidget => {
                        const widget = _.cloneDeep(prevwidget);

                        if (product_type !== 0 && suffix === '') {
                            // 人才银行第一组不一定展示
                            if (showTextAdminInfo) {
                                widget.status = {
                                    ...widget.status,
                                    hidden: true,
                                };
                            } else {
                                widget.status = {
                                    ...widget.status,
                                    hidden: false,
                                };
                            }
                        }
                        // 如果不是企业号，则只支持第一组管理员
                        if (product_type !== 0 && suffix !== '') {
                            widget.status = {
                                ...widget.status,
                                hidden: true,
                            };
                        }

                        return widget;
                    },
                );

                applyCustomConfToWidget(
                    nextWidgets,
                    'product_open_administrator_email' + suffix,
                    prevwidget => {
                        const widget = _.cloneDeep(prevwidget);

                        if (product_type !== 0 && suffix === '') {
                            // 人才银行第一组不一定展示
                            if (showTextAdminInfo) {
                                widget.status = {
                                    ...widget.status,
                                    hidden: true,
                                };
                            } else {
                                widget.status = {
                                    ...widget.status,
                                    hidden: false,
                                };
                            }
                        }
                        // 如果不是企业号，则只支持第一组管理员
                        if (product_type !== 0 && suffix !== '') {
                            widget.status = {
                                ...widget.status,
                                hidden: true,
                            };
                        }

                        return widget;
                    },
                );
            }

            return nextWidgets;
        };

        props.propsTransformer = props => {
            if (props.collapseLayout) {
                for (const colla of props.collapseLayout) {
                    if (groupsToHide.has(colla.groupKey)) {
                        colla.hidden = true;
                    }

                    if (colla.groupKey === '32_open') {
                        colla.layout = productOpenAdminBasicGroupLayout;
                    }
                }
            }
            props.labelWidth = 18;
            return props;
        };
    }

    if (tableCode === null) {
        return null;
    }

    const form = (
        <>
            {extraContent}
            <DForm {...props} />
        </>
    );
    return form;
};

export const ProcessContractProductOpenTable = observer(DemoForm);
