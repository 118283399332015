import React from 'react';
import { Modal, Select, InputNumber, Checkbox } from 'antd';
import { ITuoKeTongChildData } from '../../store';
import './index.scss';

interface IModifyChildModalProps {
    visible: boolean;
    onCancel: () => void;
    onOk: (values: IModifyChildValues) => Promise<void>;
    selectedChild?: ITuoKeTongChildData;
    initialValues?: IModifyChildValues;
}

export interface IModifyChildValues {
    subLicenseId: number;
    isAdmin: number;
    isSale: number;
    isMarket: number;
    isBatch: number;
    friend?: number;
    contact?: number;
    telephone?: number;
    direct_mobile?: number;
}

const ModifyChildModal: React.FC<IModifyChildModalProps> = ({
    visible,
    onCancel,
    onOk,
    selectedChild,
    initialValues,
}) => {
    const [values, setValues] = React.useState<IModifyChildValues>(
        initialValues || {
            subLicenseId: 0,
            isAdmin: 0,
            isSale: 0,
            isMarket: 0,
            isBatch: 0,
        },
    );

    React.useEffect(() => {
        if (initialValues) {
            setValues(initialValues);
        }
    }, [initialValues]);

    return (
        <Modal
            title="修改子账号"
            visible={visible}
            onCancel={onCancel}
            onOk={() => onOk(values)}
        >
            <div className="tuoketong-child-modify-content">
                <div className="section-title">编辑权限：</div>
                <div className="tuoketong-child-modify-flex">
                    <label>是否管理员：</label>
                    <Select
                        style={{ width: 110 }}
                        onChange={(value: number) => {
                            setValues({ ...values, isAdmin: value });
                        }}
                        value={values.isAdmin}
                    >
                        <Select.Option key={1} value={1}>
                            是
                        </Select.Option>
                        <Select.Option key={0} value={0}>
                            否
                        </Select.Option>
                    </Select>
                </div>
                <div className="tuoketong-child-modify-flex">
                    <label>是否基础款：</label>
                    <Select
                        style={{ width: 110 }}
                        onChange={(value: number) => {
                            setValues({ ...values, isSale: value });
                        }}
                        value={values.isSale}
                    >
                        <Select.Option key={1} value={1}>
                            是
                        </Select.Option>
                        <Select.Option key={0} value={0}>
                            否
                        </Select.Option>
                    </Select>
                </div>
                <div className="tuoketong-child-modify-flex">
                    <label>批量功能子账号：</label>
                    <InputNumber
                        onChange={(value: number | undefined) => {
                            setValues({ ...values, isBatch: value || 0 });
                        }}
                        value={values.isBatch}
                        min={0}
                    />
                </div>
                <div className="tuoketong-child-modify-flex">
                    <label>是否营销：</label>
                    <Select
                        style={{ width: 110 }}
                        onChange={(value: number) => {
                            setValues({ ...values, isMarket: value });
                        }}
                        value={values.isMarket}
                    >
                        <Select.Option key={1} value={1}>
                            是
                        </Select.Option>
                        <Select.Option key={0} value={0}>
                            否
                        </Select.Option>
                    </Select>
                </div>

                <div className="section-title">回收权益：</div>
                <div className="tuoketong-child-modify-flex">
                    <label>极速联系数量：</label>
                    <Checkbox
                        checked={values.contact === 1}
                        onChange={e => {
                            setValues({
                                ...values,
                                contact: e.target.checked ? 1 : 0,
                            });
                        }}
                    />
                </div>
                <div className="tuoketong-child-modify-flex">
                    <label>电话沟通数量（旧）：</label>
                    <Checkbox
                        checked={values.telephone === 1}
                        onChange={e => {
                            setValues({
                                ...values,
                                telephone: e.target.checked ? 1 : 0,
                            });
                        }}
                    />
                </div>

                <div className="tuoketong-child-modify-flex">
                    <label>电话直联数量（新）：</label>
                    <Checkbox
                        checked={values.direct_mobile === 1}
                        onChange={e => {
                            setValues({
                                ...values,
                                direct_mobile: e.target.checked ? 1 : 0,
                            });
                        }}
                    />
                </div>

                <div className="tuoketong-child-modify-flex">
                    <label>加好友数量：</label>
                    <Checkbox
                        checked={values.friend === 1}
                        onChange={e => {
                            setValues({
                                ...values,
                                friend: e.target.checked ? 1 : 0,
                            });
                        }}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default ModifyChildModal;
