import React, { useEffect, memo, useState } from 'react';

import { Modal, Form, Alert, Input, message, Table } from 'antd';
import _, { defaultTo } from 'lodash';
import { WidgetSelect, WidgetRadio } from '@/components/common/form/widgets';
import TextArea from 'antd/lib/input/TextArea';
import './preview-product-open.scss';
import { runInAction } from 'mobx';
import { MOpNameDisplayContainer, BlockLoading2, AntTable } from '@/components';
import { observer } from 'mobx-react-lite';
import { tryRefresh, defaultAxios } from '@/utils';

const paramsForV23 = [
    { key: 'expire_time', name: '过期时间' },
    { key: 'license_nbr', name: 'license数' },
    { key: 'talent_lib_version', name: '人才库版本' },
    { key: 'attention_company_nbr', name: '关注公司数量' },
    { key: 'mm_coin_balance', name: '剩余点数' },
    { key: 'batch_invite_balance', name: '剩余智能邀约劵' },
    { key: 'add_fr_balance', name: '剩余加好友劵' },
    { key: 'fast_contact_balance', name: '剩余极速联系劵' },
    // { key: 'direct_oppo_monthly_balance', name: '立即沟通月配额' },
    { key: 'add_fr_monthly_balance', name: '加好友月配额' },
    { key: 'reach_nbr_new', name: '电话沟通券' },
    { key: 'direct_oppo_balance', name: '立即沟通（团队）' },
    { key: 'direct_oppo_monthly_balance', name: '立即沟通（月配额）' },
];

const paramsForWorkbench = [
    { key: 'expire_time', name: '过期时间' },
    { key: 'license_nbr', name: 'license数' },
    { key: 'talent_lib_version', name: '人才库版本' },
    { key: 'recruit_lic', name: '招聘人员席位数' },
    { key: 'dep_lic', name: '用人经理席位数' },
    { key: 'sub_adm_num', name: '子管理员数' },
    // { key: 'pro_num', name: '项目可分配数' },
    // { key: 'recruit_lic_pro_num', name: '招聘席位项目数' },
    { key: 'reach_nbr_new', name: '电话沟通券' },
    { key: 'add_fr_monthly_balance', name: '加好友（月配额）' },
    { key: 'direct_oppo_balance', name: '立即沟通（团队）' },
    { key: 'direct_oppo_monthly_balance', name: '立即沟通（月配额）' },
    { key: 'super_exposure', name: '超级曝光' },
    { key: 'mappings_and_dynamic_switch', name: '数据报告' },
    { key: 'jobs_limit', name: '新普通职位SLOT' },
    { key: 'attention_company_nbr', name: '关注公司' },
    { key: 'uid', name: '用户UID' },
    { key: 'name', name: '用户名' },
    { key: 'cid', name: '公司CID' },
    { key: 'company', name: '客户公司' },
    { key: 'old_ordinary_position', name: '老普通职位SLOT' },
    { key: 'license_gold_medal_position', name: '金牌职位SLOT(子账号配额)' },
    { key: 'admin_gold_medal_position', name: '金牌职位SLOT(管理员配额)' },
    { key: 'ai_ticket_num', name: 'AI点数' },
    { key: 'ai_account_num', name: 'AI账号数' },
    { key: 'report_pro_num', name: '人才情报洞察尊享版' },
    { key: 'report_num', name: '人才情报洞察专业版' },
    { key: 'gold_account_num', name: '金牌账号' },
    { key: 'gold_account_monthly_balance', name: '推广点数' },
    { key: 'promotion_license_num', name: '推广席位' },
];

const paramsForTuoketong = [
    { key: 'expire_time', name: '过期时间' },
    { key: 'direct_mobile_cnt', name: '电话直联' },
    { key: 'is_sale', name: '是否基础版' },
    { key: 'is_batch', name: '是否批量' },
    { key: 'is_market', name: '是否营销' },
    { key: 'sub_license', name: '子账号数量' },
    { key: 'addfr_cnt', name: '加好友余额' },
    { key: 'uh_cnt', name: '极速联系余额' },
    { key: 'telephone_cnt', name: '电话数量' },
    { key: 'promote_cnt', name: '脉客券数量' },
];

const nilRender = (v: any) => {
    if (_.isNil(v) || '' === v) {
        return '--';
    }
    return v;
};

const talentLibMap: { [key: string]: string } = {
    '0': '无',
    '2': '低',
    '3': '中',
    '4': '高',
};

const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
};

const columns = [
    {
        title: '对比项',
        dataIndex: 'a',
        key: 'a',
    },
    {
        title: '执行前',
        render: nilRender,
        dataIndex: 'b',
        key: 'b',
    },
    {
        title: '执行后',
        render: nilRender,
        dataIndex: 'c',
        key: 'c',
    },
];

const openTypeMapWorkbench: { [k: string]: string } = {
    '1': '开通新团队', // "新签",
    '2': '已有团队续约', // "续约",
    '3': '已有团队增购', // "增购",
};

const openTypeMapV23: { [k: string]: string } = {
    '1': '新签',
    '2': '续约',
    '3': '增购',
};

const getOpenTypeMap = (version: any) => {
    if (version === 2) {
        return openTypeMapWorkbench;
    }
    return openTypeMapV23;
};

export const PreviewProductOpen: React.FC<{
    openProcessId: string | null;
    mutatingData: any;
    visible: boolean;
    onOk?: () => void;
    onGotData?: (data: any) => void;
}> = observer(({ openProcessId, mutatingData, visible, onOk, onGotData }) => {
    const [previewloading, setPreviewLoading] = useState(false);
    const [previewData, setPreviewData] = useState<any[]>([]);
    const [openType, setOepnType] = useState<number | null>(null);
    const [version, setVersion] = useState<number | null>(null);
    const [errstr, setErrstr] = useState('');
    const [data, setData] = useState<any>({});

    useEffect(() => {
        if (openProcessId === null || visible === false) {
            return;
        }

        (async () => {
            setPreviewLoading(true);
            const [d, e] = await defaultAxios.get(
                '/crm/workflow/contractproduct/pexecute',
                {
                    openProcessId,
                },
                {
                    silent: true,
                },
            );
            setPreviewLoading(false);
            if (!d) {
                if (e) {
                    setErrstr(e.message);
                }
                return;
            }

            let { data } = d;
            data = data || {};
            let {
                adm_user,
                old_detail,
                new_detail,
                version,
                open_type,
                product_type,
            } = data;
            adm_user = adm_user || {};
            if (!old_detail || !new_detail) {
                message.error(
                    '缺数据: ' +
                        'adm_user, old_detail, new_detail: ' +
                        adm_user +
                        ',' +
                        old_detail +
                        ',' +
                        new_detail,
                );
                return;
            }

            setData(data);

            onGotData && onGotData(data);
            onOk && onOk();
            const nextPreviewData: any[] = [];

            // v2/3 与 工作台字段不同
            const isWorkbench = version === 2;
            let params = isWorkbench ? paramsForWorkbench : paramsForV23;
            if (!isWorkbench) {
                nextPreviewData.push({
                    a: '管理员姓名',
                    b: adm_user.name,
                    c: adm_user.name,
                });

                nextPreviewData.push({
                    a: '管理员uid',
                    b: adm_user.uid,
                    c: adm_user.uid,
                });

                nextPreviewData.push({
                    a: '账号cid',
                    b: adm_user.cid,
                    c: adm_user.cid,
                });

                nextPreviewData.push({
                    a: '账号所在公司',
                    b: adm_user.company,
                    c: adm_user.company,
                });
            }

            if (product_type === 4) {
                params = paramsForTuoketong;
            }

            params.forEach(param => {
                if (param.key === 'talent_lib_version') {
                    nextPreviewData.push({
                        a: param.name,
                        b: talentLibMap[old_detail.talent_lib_version],
                        c: talentLibMap[new_detail.talent_lib_version],
                    });
                } else {
                    nextPreviewData.push({
                        a: param.name,
                        b: old_detail[param.key],
                        c: new_detail[param.key],
                    });
                }
            });
            setPreviewData(nextPreviewData);
            setOepnType(open_type);
            setVersion(version);
        })();
    }, [openProcessId, visible]);

    if (!openProcessId) {
        return null;
    }

    return (
        <>
            {previewloading ? (
                <BlockLoading2 />
            ) : errstr ? (
                <Alert type="error" message={errstr} />
            ) : (
                <>
                    {version !== null && openType !== null && (
                        <div style={{ paddingBottom: 8 }}>
                            开通类型：<b>{getOpenTypeMap(version)[openType]}</b>
                        </div>
                    )}
                    {version === 2 && (
                        <div style={{ paddingBottom: 8 }}>
                            <div>
                                <span
                                    style={{
                                        display: 'inline-block',
                                        width: 120,
                                        color: '#999',
                                    }}
                                >
                                    <span style={{ color: 'red' }}>*</span>
                                    主管理员手机号：
                                </span>
                                <span>{data?.phone || '无'}</span>
                            </div>
                            <div>
                                <span
                                    style={{
                                        display: 'inline-block',
                                        width: 120,
                                        color: '#999',
                                    }}
                                >
                                    <span style={{ color: 'red' }}>*</span>
                                    团队名称：
                                </span>
                                <span>{data?.team_name || '无'}</span>
                            </div>
                            <div>
                                <span
                                    style={{
                                        display: 'inline-block',
                                        width: 120,
                                        color: '#999',
                                    }}
                                >
                                    主管理员邮箱：
                                </span>
                                <span>{data?.email || '无'}</span>
                            </div>
                            <div>
                                <span
                                    style={{
                                        display: 'inline-block',
                                        width: 120,
                                        color: '#999',
                                    }}
                                >
                                    服务周期（月）：
                                </span>
                                <span>{data?.duration || '无'}</span>
                            </div>
                        </div>
                    )}
                    <AntTable
                        columns={columns}
                        dataSource={previewData}
                        pagination={false}
                    />
                </>
            )}
        </>
    );
});
