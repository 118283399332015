import React, { useState } from 'react';
import { Modal, Checkbox, message } from 'antd';
import { defaultAxios } from '@/utils';
import { IMemberListStagingChildData } from '../../store';
import './index.scss';

interface IRightCollectModalProps {
    modalVis: boolean;
    setModalVis: (vis: boolean) => void;
    selectedChild?: IMemberListStagingChildData;
    onSuccess: () => void;
}

const RightCollectModal: React.FC<IRightCollectModalProps> = ({
    modalVis,
    setModalVis,
    selectedChild,
    onSuccess,
}) => {
    const [selectedRights, setSelectedRights] = useState<string[]>([]);

    const handleCollectRights = async () => {
        if (!selectedChild || selectedRights.length === 0) {
            message.warning('请选择要回收的权益');
            return;
        }

        const [res, err] = await defaultAxios.post(
            '/crm/member/workBenchRightCollect',
            {
                license_id: selectedChild.id,
                telephone_coupon: selectedRights.includes('telephone_coupon')
                    ? 1
                    : 0,
                super_exposure_coupon: selectedRights.includes(
                    'super_exposure_coupon',
                )
                    ? 1
                    : 0,
                gold_job_coupon: selectedRights.includes('gold_job_coupon')
                    ? 1
                    : 0,
                ai_ticket: selectedRights.includes('ai_ticket') ? 1 : 0,
            },
        );

        if (err || !res) {
            message.error('回收权益失败');
            return;
        }

        message.success('回收权益成功');
        setModalVis(false);
        setSelectedRights([]);
        onSuccess();
    };

    const rightOptions = [
        {
            label: '电话沟通',
            value: 'telephone_coupon',
        },
        {
            label: '超级曝光',
            value: 'super_exposure_coupon',
        },
        {
            label: '金牌职位SLOT(非配额)',
            value: 'gold_job_coupon',
        },
        {
            label: 'Ai点数',
            value: 'ai_ticket',
        },
    ];

    return (
        <Modal
            title="回收权益"
            visible={modalVis}
            onCancel={() => {
                setModalVis(false);
                setSelectedRights([]);
            }}
            onOk={handleCollectRights}
            width={500}
        >
            <div className="right-collect-modal">
                <div className="right-collect-title">
                    请选择需要回收的权益：
                </div>
                <Checkbox.Group
                    options={rightOptions}
                    value={selectedRights}
                    onChange={values => setSelectedRights(values as string[])}
                />
                <div className="right-collect-tips">注：权益会回收到团队中</div>
            </div>
        </Modal>
    );
};

export default RightCollectModal;
